export const API_BASE_URL = "https://maruticreations.in/api/v1";
// export const API_BASE_URL = "http://localhost:3001/api/v1";

// Live
//export const API_BASE_URL = "https://api.vakilkaro.com/api/v1";
export const S3_BASE_URL =
  "https://sqlappupload.s3.ap-south-1.amazonaws.com/micro-finence";

//Company name
export const COMPANY_NAME = "Vasu Dev Loka Finance";
